<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> Editar Ruleta </template>
    <div>
      <form @submit.prevent="save()">
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Pleno</label>
              <vs-input
                v-model="roulette.pleno"
                border
                class="shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.roulette.pleno.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.roulette.pleno.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.roulette.pleno.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Semi Pleno</label>
              <vs-input
                v-model="roulette.semipleno"
                border
                class="shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.roulette.semipleno.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.roulette.semipleno.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.roulette.semipleno.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Calle </label>
              <div>
                <vs-input
                  v-model="roulette.calle"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.roulette.calle.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.roulette.calle.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.roulette.calle.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Cuadro</label>
              <div>
                <vs-input
                  v-model="roulette.cuadro"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.roulette.cuadro.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.roulette.cuadro.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.roulette.cuadro.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Linea</label>
              <div>
                <vs-input
                  v-model="roulette.linea"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.roulette.linea.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.roulette.linea.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.roulette.linea.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Columna</label>
              <div>
                <vs-input
                  v-model="roulette.columna"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.roulette.columna.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.roulette.columna.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.roulette.columna.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Docena</label>
              <div>
                <vs-input
                  v-model="roulette.docena"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.roulette.docena.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.roulette.docena.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.roulette.docena.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Chance simple</label>
              <div>
                <vs-input
                  v-model="roulette.chanceSimple"
                  border
                  class="shadow-lg"
                />
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Cubre</label>
              <div>
                <vs-input
                  v-model="roulette.cubre"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.roulette.cubre.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.roulette.cubre.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.roulette.cubre.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Special Calle</label>
              <div>
                <vs-input
                  v-model="roulette.specialCalle"
                  border
                  class="shadow-lg"
                />
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Logo</label>
              <div>
                <vs-input
                  v-model="roulette.logo"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.roulette.logo.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.roulette.logo.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.roulette.logo.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="mb-3 mb-0">
          <div class="d-flex">
            <vs-button type="submit">Guardar</vs-button>
            <vs-button @click="closeModa()" type="button" success>
              Cancelar
            </vs-button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    operator: {
      type: Object,
      default: () => {
        return {};
      },
    },
    roulette: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      fichas: [
        { valor: 0.1 },
        { valor: 0.5 },
        { valor: 1 },
        { valor: 5 },
        { valor: 10 },
      ],
    };
  },
  validations: {
    roulette: {
      pleno: {
        required,
      },
      semipleno: {
        required,
      },
      calle: {
        required,
      },
      cuadro: {
        required,
      },
      linea: {
        required,
      },
      columna: {
        required,
      },
      docena: {
        required,
      },
      chanceSimple: {
        required,
      },
      cubre: {
        required,
      },
      logo: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess',
    }),
  },
  methods: {
    async save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) return console.log('error');

      const operatorId = this.operator._id;
      const { _id, ...dataToUpdate } = this.roulette;

      await this.updateOperatorRoulettes({
        operatorId,
        _id,
        dataToUpdate,
      });

      if (!this.success)
        return Swal.fire('Error editando operador', '', 'error');

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Ruleta actualizada con exito',
        showConfirmButton: false,
        timer: 1500,
      });

      this.closeModa();
    },
    closeModa() {
      this.$emit('closeModal');
      this.modal = false;
    },
    ...mapActions({
      updateOperatorRoulettes: 'operators/updateOperatorRoulettes',
    }),
  },
};
</script>
